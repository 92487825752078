.header {
  display: flex;
  align-items: center;
  justify-content: flex-start; /* Align items to the start */
  /* margin-bottom: 10px; */
  padding-left: var(--Left-Padding);
}
.imageWrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.header h1 {
    margin: 0;
    /* flex-grow: 1; Allows h2 to take up remaining space */
    font-size: 25px;
    font-weight: 800;
    /* padding-right: 16px; */
    /* padding-bottom: 3px; */
    margin: 0px;
    color: white;
    padding-bottom: 10px;
}

@media (max-width:500px){
  .header h1 {
    font-size: 20px;
}
}
.viewMoreButton {
  padding: 8px 16px;
  font-size: 14px;
  font-weight: 600;
  /* background-color: #0070f3; */
  color: #A48EFF  ;
  text-decoration: none;
  border-radius: 20px;
  cursor: pointer;
  margin-left: 10px; /* Space between heading and button */
}

.viewMoreButton:hover {
  color: #ffffff ;
  background-color: #00c2ff;
}

/* .game_section  */

.game_section {

    overflow-x: auto;
      overflow-y: hidden;
      white-space: nowrap;
      padding-left: var(--Left-Padding);
  }
  
  .game_section::-webkit-scrollbar {
    display: none;
  }
  
  /* Hide scrollbar for IE, Edge and Firefox */
  .game_section {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }

  /* --------------------- grid_container --------------------------*/

  .grid_container {
    display: grid;
    grid-auto-flow: row dense;
    grid-template-columns: repeat(8, 1fr);
    /* grid-template-columns: repeat(auto-fill, var(--grid_width)); */
    justify-content: start;
    grid-gap: 12px;
    grid-column-start: span 2;
    margin: 10px 10px 20px 0px;
  }
  
  .title {
    display: flex;
    align-items: flex-end;
    position: absolute;
    align-content: center;
    justify-content: center;
    text-align: center;
    width: 100%;
    height: 105%;
    color: var(--white);
    opacity: 0;
    font-size: 14px;
    font-weight: 800;
    text-shadow: 0 2px 2px #000;
    text-wrap: pretty;
    padding: 0px 5px;
}

.thumb_container {
  overflow: hidden;
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

.thumb-container:hover > .thumb_img {
  -webkit-transition: all 100ms ease-in;
  -webkit-transform: scale(1.2);
  -ms-transition: all 100ms ease-in;
  -ms-transform: scale(1.2);
  -moz-transition: all 100ms ease-in;
  -moz-transform: scale(1.2);
  transition: all 100ms ease-in;
  transform: scale(1.2);
}

.thumb_img {
  width: 100%;
  height: 100%;
  -webkit-transition: all 200ms ease-in;
  transition: all 200ms ease-in;
  -ms-transition: all 200ms ease-in;
  -moz-transition: all 200ms ease-in;
}

.cover {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgb(0, 0, 0);
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.8) 0%,
    rgba(0, 0, 0, 0.1) 30%,
    rgba(255, 255, 255, 0) 50%
  );
  opacity: 0;

}

.thumb_1x1:hover > .thumb_container > .cover {
  opacity: 1;
  transition: opacity 0.2s;
  border-radius: calc(10px);
  border-width: 4px;
  border-style: solid ;
  border-color: rgb(239 184 15);
  z-index: 2; /* Ensure video is on top */
}

.thumb_2x2{
  grid-row: auto/span 2 !important; 
  width: var(--Hot_grid_width);

}

.thumb_1x1{
  grid-column: auto/span 1;
  grid-row: auto/span 1;
  aspect-ratio: 16/9;
  /* min-height: var(--grid_height);
  min-width: var(--grid_width); */
  display: block;
  position: relative;
  border-radius: 10px;
  box-shadow: 0 0 10px rgb(0 0 0 / 40%);
  transition: all 0.2s;
  /*overflow: hidden;*/
  background-repeat: no-repeat;
  background-size: cover;
}

.thumb-1x1:hover {
  z-index: 5;
  -webkit-transition: all 100ms ease-in;
  -webkit-transform: scale(1.1);
  -ms-transition: all 100ms ease-in;
  -ms-transform: scale(1.1);
  -moz-transition: all 100ms ease-in;
  -moz-transform: scale(1.1);
  transition: all 100ms ease-in;
  transform: scale(1.1);
}

.thumb_1x1 > .new_badge {
  display: flex;
  position: absolute;
  width: 55px;
  height: 55px;
  transform: translate(-4px, -4px);
  z-index: 3;
  background-repeat: no-repeat;
  background-size: cover;
}

.thumb_1x1:hover > .new_badge {
  display: none;
}

.thumb_1x1:hover > .thumb_container > .title {
  z-index: 3;
  opacity: 1;
  height: 95%;
  transition: height 0.1s;
}
/* ------------------ scrollButton ------------- */
.scrollButton {
  /* background-color: #0070f3; */
  color: white;
  border: none;
  padding: 8px 12px;
  cursor: pointer;
  border-radius: 5px;
  margin-left: 5px; /* Space between the scroll buttons */
}

.scrollButton:hover {
  background-color: #005bb5;
}

.scrollContainer {
  overflow-x: auto;
  white-space: nowrap;
}

.scrollContent {
  display: flex;
}


.gameVideo {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit:fill;
  z-index: 1; /* Ensure video is on top */
}

.imageOverlay {
  z-index: 0; /* Ensure image is below the video */
}



@media (max-width: 600px) {

  .grid_container {
    grid-template-columns: repeat(2, 1fr);
  }

  
}

@media (min-width: 600px) and (max-width: 800px) {

  .grid_container {
    grid-template-columns: repeat(3, 1fr);
  }
  
}

@media (min-width: 800px) and (max-width: 1000px) {

  .grid_container {
    grid-template-columns: repeat(4, 1fr);
  }
  
}

@media (min-width: 1000px) and (max-width: 1200px) {

  .grid_container {
    grid-template-columns: repeat(5, 1fr);
  }
  
}

@media (min-width: 1200px) and (max-width: 1600px) {

  .grid_container {
    grid-template-columns: repeat(6, 1fr);
  }
  
}

@media (min-width: 1600px) and (max-width: 1800px) {

  .grid_container {
    grid-template-columns: repeat(7, 1fr);
  }
  
}